<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { isFuture, isBefore } from 'date-fns';
import { useRoute } from 'vue-router';
import { formatDistanceToNow } from '@utils/date-fns';
import { default as ButtonEl } from '@components/shared/Button.vue';
import { get } from 'lodash';
import CoverView from '@views/shared/CoverView.vue';
import MediaIcons from '@components/shared/MediaIcons.vue';
import TTS from '@components/shared/Tts.vue';
import MediaPreview from '@views/overlays/MediaPreview.vue';
import MediaList from '@views/shared/MediaList.vue';
import PinFlow from '@utils/pinFlow';
import i18n from '@i18n';
import DateTimeBlock from '@components/shared/blocks/DateTimeBlock.vue';
import PeopleBlock from '@components/shared/blocks/PeopleBlock.vue';
import DepartmentsBlock from '@components/shared/blocks/DepartmentsBlock.vue';
import DescriptionBlock from '@components/shared/blocks/DescriptionBlock.vue';
import PictogramsBlock from '@components/shared/blocks/PictogramsBlock.vue';
import TitleBlock from '@components/shared/blocks/TitleBlock.vue';
import DeadlineBlock from '@components/shared/blocks/DeadlineBlock.vue';
import PriceBlock from '@components/shared/blocks/PriceBlock.vue';
import ClubBlock from '@components/shared/blocks/ClubBlock.vue';
import InstitutionBlock from '@components/shared/blocks/InstitutionBlock.vue';
import SignupsBlock from '@components/shared/blocks/SignupsBlock.vue';

const store = useStore();
const route = useRoute();

const opt = ref('');
const mediaType = ref('');
const selectedMediaIndex = ref(-1);
const hideMediaPreview = ref(false);
const showMediaList = ref(false);

const activity = computed(() => {
  return store.getters['activities/selected'];
});

const activeProfile = computed(() => {
  return store.getters['profiles/activeProfile'];
});

const pinValidation = computed(() => {
  return store.getters['profiles/pinValidation'];
});

const institutionSettings = computed(() => {
  return store.getters['institution/settings'];
});

const moduleColor = computed(() => {
  return route?.meta?.color;
});

const activityHideSignups = computed(() => {
  return get(institutionSettings.value, 'screen.activityHideSignups', false);
});

const profilesNotAlreadySignedUp = computed(() => {
  const departmentProfiles = store.getters['profiles/all'];
  return departmentProfiles.filter(
    (profile) =>
      !activity?.value?.participants.some(
        (signedUpProfile) => signedUpProfile.id === profile.id
      )
  );
});

const imageUrl = computed(() => {
  return get(activity.value, 'coverImage.source', '/img/placeholder.png');
});

const signupPossible = computed(() => {
  return activity.value.signupPossible && !activity.value.cancelled;
});

const signupExpired = computed(() => {
  return (
    !activity.value.signupPossible &&
    !isBefore(new Date(), new Date(activity.value.signupEnd))
  );
});

const maxSignupsReached = computed(() => {
  if (!activity.value.maxSignups) return false;
  return (
    activity.value.participants.length + activity.value.responsible.length >=
    activity.value.maxSignups
  );
});

const optoutPossible = computed(() => {
  return (
    isFuture(new Date(activity.value.startDate)) &&
    !activity.value.cancelled &&
    activity.value.signupEnabled
  );
});

const mediaIcons = computed(() => {
  return [
    {
      name: 'pictures',
      fa: 'image',
      amount: activity.value.pictures.length,
    },
    {
      name: 'videos',
      fa: 'film',
      amount: activity.value.videos.length,
    },
    {
      name: 'documents',
      fa: 'file-alt',
      amount: activity.value.documents.length,
    },
  ];
});

const mediaItems = computed(() => {
  let items;

  switch (mediaType.value) {
    case 'pictures':
      items = activity.value.pictures;
      break;
    case 'videos':
      items = activity.value.videos;
      break;
    case 'documents':
      items = activity.value.documents;
      break;
    default:
      items = [];
  }
  return items;
});

const showMediaPreview = computed(() => {
  return selectedMediaIndex.value > -1 && !hideMediaPreview.value;
});

const isAlreadyOptedInOut = computed(() => {
  const participantIds = activity.value.participants.map(
    (participant) => participant.id
  );

  return participantIds.includes(activeProfile.value.id);
});

watch(pinValidation, (pinData) => {
  if (!pinData.validating) return;

  const signupData = {
    subscription: {
      id: activity.value.id,
    },
    profile: {
      profileId: activeProfile.value.id,
      profileType: activeProfile.value.type,
      pin: pinData.pin.toString(),
    },
    opt: opt.value,
  };

  if (
    (opt.value === 'in' && !isAlreadyOptedInOut.value) ||
    (opt.value === 'out' && isAlreadyOptedInOut.value)
  ) {
    store.dispatch('activities/activitySignupScreen', signupData);
  }
});

onMounted(() => {
  store.dispatch('activities/getSelectedActivity', route.params.id);
});

function formatDeadline(activity) {
  if (activity.signupPossible) {
    return `${i18n.global.t('global.signUpDeadline')} ${formatDistanceToNow(new Date(activity.signupEnd), { addSufix: true })}`;
  }
  return i18n.global.t('global.signupNotNeeded');
}

function showProfiles(profileType) {
  store.dispatch('general/setActiveOverlay', {
    name: 'participants-list',
    data: {
      title: `global.${profileType}`,
      profiles: activity.value[profileType],
    },
  });
}

function optInOrOut(payload) {
  opt.value = payload;

  const flow = new PinFlow({
    entity: 'activity',
    text: 'activity.theActivity',
    opt: opt.value,
    title: activity.value.title,
    participants:
      payload === 'in'
        ? profilesNotAlreadySignedUp.value
        : activity.value.participants,
    sharedDepartments: activity.value.departments,
  });
  flow.startSignupFlow('activity');
}

function closeMediaList() {
  mediaType.value = '';
  selectedMediaIndex.value = -1;
  showMediaList.value = false;
}

function mediaIconSelected(payload) {
  mediaType.value = payload;

  if (mediaItems.value.length === 1) {
    // if only one item, go straight to media-preview
    selectedMediaIndex.value = 0;
    hideMediaPreview.value = false;
    return;
  }

  showMediaList.value = true;
}
</script>

<template>
  <div class="activity">
    <media-preview
      v-if="showMediaPreview"
      :selected-item-index="selectedMediaIndex"
      :media-type="mediaType"
      :items="mediaItems"
      :shared-departments="activity.departments"
      @close-preview="closeMediaList"
    />

    <cover-view
      v-if="activity.id"
      :image-url="imageUrl"
      :gradient="true"
      :module-color="moduleColor"
      :margin-top="65"
    >
      <div class="activityContent tts-content pt-20">
        <media-icons
          :icons="mediaIcons"
          :media-type="mediaType"
          :amount="mediaItems.length"
          @media-selected="mediaIconSelected"
        />
        <TTS entity-type="ACTIVITY" />

        <div v-if="!showMediaList">
          <TitleBlock :text="activity.title"></TitleBlock>

          <PictogramsBlock
            v-if="activity.pictograms.length"
            :pictograms="activity.pictograms"
          />

          <DescriptionBlock :description="activity.description" />

          <dateTimeBlock :start="activity.startDate" :end="activity.endDate" />

          <DeadlineBlock
            v-if="activity.signupPossible"
            :signup-expired="signupExpired"
            :text="formatDeadline(activity)"
          />

          <PriceBlock
            v-if="!!activity.price"
            :text="$n(activity.price[0].price / 100, 'currency')"
          />

          <PeopleBlock
            v-if="activity.participants.length"
            text="global.participantsPlural"
            :hide-participants="activityHideSignups"
            :module-color="moduleColor"
            :profiles="activity.participants"
            @show-people="showProfiles('participants')"
          />

          <PeopleBlock
            v-if="activity.responsible.length"
            text="global.responsiblePlural"
            :module-color="moduleColor"
            :profiles="activity.responsible"
            @show-people="showProfiles('responsible')"
          />

          <DepartmentsBlock
            v-if="activity.departments.length"
            :departments="activity.departments"
          />

          <SignupsBlock
            v-if="maxSignupsReached"
            :max-signups-reached="maxSignupsReached"
            :activity="activity"
          />

          <ClubBlock
            v-if="activity.club"
            :image="activity.club.logo.source"
            :text="activity.club.name"
          />

          <InstitutionBlock />
        </div>

        <div v-else>
          <media-list
            :items="mediaItems"
            :media-type="mediaType"
            :can-close="true"
            class="pt-20"
            @close="closeMediaList"
            @item-selected="selectedMediaIndex = $event"
          />
        </div>
      </div>

      <div
        v-if="!showMediaList"
        class="activityActions flex w-max left-full sticky"
      >
        <button-el
          v-if="signupPossible"
          text="global.signUp"
          icon="check"
          background-color="success"
          text-color="white"
          class="mr-5 shadow-xsm"
          @click="optInOrOut('in')"
        />

        <button-el
          v-if="optoutPossible"
          text="global.optOut"
          icon="minus-circle"
          background-color="error"
          text-color="white"
          class="shadow-xsm"
          @click="optInOrOut('out')"
        />
      </div>
    </cover-view>
  </div>
</template>

<style lang="scss">
.activity {
  .activityContent {
    &.participants {
      margin-top: 700px;
    }

    > *:not(.mediaIcons) {
      padding: 0 100px;
    }
  }

  .activityActions {
    bottom: 70px;
    padding-right: 80px;
  }
}
</style>
